<template>
  <div class="index-container">
    <index-header />
    <div class="index-content">
      <div class="scrollbar">
        <router-view></router-view>
        <index-footer />
      </div>
    </div>
    <template>
      <el-backtop :visibility-height="100" :bottom="60"></el-backtop>
    </template>
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader";
import IndexFooter from "@/components/IndexFooter";
// import DevicePixelRatio from '@/util/devicePixelRatio.js'

export default {
  name: "nav-home",
  components: {
    IndexHeader,
    IndexFooter,
  },
  data() {
    return {};
  },
  created() {
    // new DevicePixelRatio().init();
    // let dname = window.location.host;
    // getTenantInfo({ code: dname }).then((res) => {
    //   if(res.data){
    //     if (res.data.type == 1) {
    //       window.location.href = 'http://' + `${dname}/#/template-first`;
    //     } else if (res.data.type == 2) {
    //       window.location.href = 'http://' + `${dname}/#/template-second`;
    //     }  else if (res.data.type == 3) {
    //       window.location.href = 'http://' + `${dname}/#/template-third`;
    //     } else {
    //       window.location.href = 'http://' + `${dname}/#/template-fourth`;
    //       // window.location.href = 'http://' + `${dname}/#/index`;
    //     }
    //     window.localStorage.setItem("domainTid", res.data.tenantId);
    //   }else {
    //     window.location.href = 'http://' + `${dname}/#/index`;
    //   }
    // })
  },
};
</script>
