<template>
  <div class="header-bar">
    <div class="wrapper-content flex">
      <h1 class="header-logo" @click="golink">
        <a v-if="logo" href="javascript:;">
          <div class="logoimg">
            <img :src="loadUrl(logo)" alt="" />
          </div>
        </a>
        <a v-else href="javascript:;">
          <div class="logotext">
            <img src="../assets/image/favicon.png" alt="" />
            松冬智慧体育服务平台
          </div>
          <!-- <div class="logoimg">
            <img src="../assets/image/logo2.png" alt="" />
          </div> -->
          <!-- <img class="logoimg2" src="../assets/head_logo.png" alt="" /> -->
        </a>
      </h1>
      <el-menu
        class="pcMenu"
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleSelect"
        router
      >
        <template v-for="item in menu_list">
          <el-submenu
            v-if="item.navigationName && item.childrens != null"
            :key="item.sort"
            :index="item.router"
          >
            <template slot="title">{{ item.navigationName }}</template>
            <el-menu-item
              v-for="subItem in item.childrens"
              :key="subItem.index"
              :index="subItem.router"
              >{{ subItem.navigationName }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item v-else :key="item.sort" :index="item.router">{{
            item.navigationName
          }}</el-menu-item>
        </template>
      </el-menu>
      <user-bar />
      <div class="cdBox">
        <img
          @click="menuShowClick"
          :src="menuShow ? menuIcon.closeSrc : menuIcon.src"
          alt=""
        />
        <el-collapse-transition>
          <div class="submenuBox" v-if="menuShow">
            <el-menu
              :default-active="activeIndex"
              @select="handlePhoneSelect"
              router
              background-color="rgba(0, 0, 0, 0.6)"
              text-color="#ffffff"
              active-text-color="#1e50ae"
              :unique-opened="true"
            >
              <template v-for="item in menu_list">
                <el-submenu
                  v-if="item.navigationName && item.childrens != null"
                  :key="item.sort"
                  :index="item.router"
                >
                  <template slot="title">{{ item.navigationName }}</template>
                  <el-menu-item
                    v-for="subItem in item.childrens"
                    :key="subItem.index"
                    :index="item.router"
                    >{{ subItem.navigationName }}</el-menu-item
                  >
                </el-submenu>
                <el-menu-item v-else :key="item.sort" :index="item.router">{{
                  item.navigationName
                }}</el-menu-item>
              </template>
            </el-menu>
          </div>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { getTenantMenu, getTenant } from "@/api/user.js";
import UserBar from "./UserBar.vue";
export default {
  name: "IndexHeader",
  components: {
    UserBar,
  },
  data() {
    return {
      logo: "",
      type: "",
      rehref: "",
      activeIndex: "首页",
      tenant_id: "",
      menu_list: [],
      menuShow: false,
      menuIcon: {
        src: require("@/assets/image/caidan.png"),
        closeSrc: require("@/assets/image/guanbi.png"),
      },
      // menu_list: [
      //   {
      //     sort: 1,
      //     navigationName: "首页",
      //     router: "/index",
      //   },
      //   {
      //     sort: 2,
      //     navigationName: "产品中心",
      //     router: "/product",
      //   },
      //   {
      //     sort: 3,
      //     navigationName: "赛事活动",
      //     router: "/events",
      //     childrens: [
      //       {
      //         sort: 3 - 1,
      //         navigationName: "体育赛事",
      //         router: "/events",
      //       },
      //       {
      //         sort: 3 - 2,
      //         navigationName: "体育活动",
      //         router: "/events",
      //       },
      //     ],
      //   },
      //   {
      //     sort: 4,
      //     navigationName: "培训教学",
      //     router: "/train",
      //     childrens: [
      //       {
      //         sort: 4 - 1,
      //         navigationName: "体育培训",
      //         router: "/train",
      //       },
      //       {
      //         sort: 4 - 2,
      //         navigationName: "教学服务",
      //         router: "/train",
      //       },
      //     ],
      //   },
      //   {
      //     sort: 5,
      //     navigationName: "场馆服务",
      //     router: "/course",
      //     childrens: [
      //       {
      //         sort: 5 - 1,
      //         navigationName: "场馆运营",
      //         router: "/course",
      //       },
      //       {
      //         sort: 5 - 2,
      //         navigationName: "场馆租赁",
      //         router: "/course",
      //       },
      //     ],
      //   },
      //   {
      //     sort: 6,
      //     navigationName: "合作伙伴",
      //     router: "/partners",
      //   },
      //   {
      //     sort: 7,
      //     navigationName: "关于我们",
      //     router: "/about",
      //   },
      // ],
    };
  },
  computed: {
    active() {
      return this.$store.state.headactive;
    },
  },
  watch: {
    active(newv) {
      this.activeIndex = newv;
    },
  },
  mounted() {
    this.activeIndex = this.active;
    this.tenant_id = window.localStorage.getItem("tenant_id") || "sundo";
    if (this.tenant_id != "sundo") {
      this.getTenantLogo();
    }
    this.getMenu();
  },
  methods: {
    menuShowClick() {
      this.menuShow = !this.menuShow;
    },
    handlePhoneSelect(key) {
      this.activeIndex = key;
      this.$store.dispatch("saveHeadActive", key);
      this.menuShow = false;
    },
    handleSelect(key,keyPath) {
      this.activeIndex = key;
      this.$store.dispatch("saveHeadActive", key);
      this.menu_list.forEach(item=>{
        if(item.router == keyPath[0] && item.childrens) {
          item.childrens.forEach(dates=>{
            if(key == dates.router){
              item.navigationName = dates.navigationName
            }
          })
        }
      })
    },
    golink() {
      if (this.type != "") {
        window.location.href = this.rehref;
      } else {
        this.$router.push("/index");
      }
    },
    // 获取租户的导航数据
    getMenu() {
      const self = this;
      self.$store.commit("showLoading");
      let tenantId = this.tenant_id; // 'c91c60e038884022bdbc1498dd9b29b3'
      getTenantMenu(null, tenantId).then((res) => {
        if (res.code == 200) {
          let menu_list = res.data;
          menu_list.map((menu) => {
            if (menu.childrens) {
              menu.childrens.map((item) => {
                item.index = `${menu.sort} - ${item.sort}`;
              });
            }
          });
          this.menu_list = menu_list;
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    // 获取租户的logo
    getTenantLogo() {
      const self = this;
      let ip = window.location.href.split("/#")[0];
      getTenant().then((res) => {
        if (res.code == 200 && res.data) {
          this.type = res.data.type;
          this.logo = res.data.indexLogo;
          this.rehref =
            res.data.type == 1
              ? `${ip}/#/template-first`
              : res.data.type == 2
              ? `${ip}/#/template-second`
              : res.data.type == 3
              ? `${ip}/#/template-third`
              : res.data.type == 4
              ? `${ip}/#/template-fourth`
              : res.data.type == 5
              ? `${ip}/#/template-fifth`
              : res.data.type == 6
              ? `${ip}/#/template-sixth`
              : res.data.type == 7
              ? `${ip}/#/template-seventh`
              : res.data.type == 8
              ? `${ip}/#/template-eighth`
              : res.data.type == 9
              ? `${ip}/#/template-ninth`
              : res.data.type == 10 && !this.isMobile()
              ? `${ip}/#/template-tenth`
              : res.data.type == 10 && this.isMobile()
              ? `${ip}/#/template-tenthMobile`
              : "";
        } else {
          self.$message(res.msg);
        }
      });
    },
    isMobile() {
      // console.log(navigator.userAgent)
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #ececec;
  justify-content: space-around;
  .header-logo {
    // width: 267px;
    a {
      .logotext {
        font-size: 24px;
        color: #333333;
        line-height: 48px;
        img {
          width: 48px;
          height: 48px;
          vertical-align: top;
        }
      }
      .logoimg {
        position: relative;
        width: 130px;
        display: inline-block;
        // &::after {
        //   position: absolute;
        //   content: "";
        //   width: 1px;
        //   height: 25px;
        //   background-color: #cccccc;
        //   right: -16px;
        //   top: 50%;
        //   transform: translateY(-50%);
        // }
      }
      .logoimg2 {
        width: 267px;
        margin-left: 30px;
      }
    }
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0;
  }
  .el-menu--horizontal > .el-menu-item,
  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 16px;
    padding: 0 21px;
    height: 70px;
    line-height: 70px;
    color: #333;
    &:not(.is-disabled):hover {
      color: #1e50ae;
      background: #fff;
    }
    &.is-active:focus,
    &.is-active {
      color: #fff;
      background: #1e50ae;
    }
  }
  .flex {
    align-items: center;
  }
  .top-menu {
    .icon-touxiang {
      font-size: 40px;
    }
    .el-dropdown {
      cursor: pointer;
    }
    .login-link {
      display: inline-block;
      padding-left: 20px;
      font-size: 14px;
      color: #666;
      span {
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        &:hover {
          color: #1e50ae;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .pcMenu {
    display: block;
  }
  .cdBox {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .pcMenu {
    display: none;
  }
  .cdBox {
    display: block;
  }
  .header-bar {
    height: 60px;
    .flex {
      .top-menu {
        display: none;
      }
      .header-logo {
        // width: 160px;
        // margin-top: 10px;
        a {
          .logotext {
            line-height: 38px;
            font-size: 22px;
            img {
              width: 38px;
              height: 38px;
            }
            // &::after {
            //   position: absolute;
            //   content: "";
            //   width: 1px;
            //   height: 20px;
            //   background-color: #cccccc;
            //   right: -8px;
            //   top: 30%;
            // }
          }
          .logoimg2 {
            width: 150px;
            margin-left: 16px;
          }
        }
      }
      .cdBox {
        position: relative;
        flex: 0 0 1;
        padding-left: 16px;
        .submenuBox {
          position: fixed !important;
          width: 100vw;
          left: 0;
          top: 60px;
          text-align: left;
          .el-menu {
            position: relative;
            font-size: 16px;
            z-index: 999;
          }
        }
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
</style>
