<template>
  <div class="footer-content">
    <div>
      <!-- <p>技术支持：中讯智慧体育研究院</p> -->
      <p>地址：广东省广州市天河区科华街251号乐天创意园B3006</p>
      <p>粤ICP备14033869号 ©2020-2030 广州松冬体育信息咨询有限公司</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "IndexFooter",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 200px;
  background: #2e323b;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #e2e2e2;
  text-align: center;
  p {
    width: 100%;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-content {
    height: 160px;
  }
}
</style>
